<template>
  <v-snackbar
    v-model="model"
    top
    :timeout="$store.state.snackbar.timeout"
    :color="$store.state.snackbar.color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $store.state.snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$store.commit('snackbar/SET_CLOSE')">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  computed: {
    model: {
      get() {
        return this.$store.getters["snackbar/getModel"];
      },
      set(v) {
        return this.$store.commit("snackbar/SET_MODEL", v);
      },
    },
  },
};
</script>
