<template>
  <v-app>
    <base-header />
    <v-main class="content">
      <base-snackbar />
      <div class="pa-6">
        <!-- <v-container> -->
        <router-view />
        <!-- </v-container> -->
      </div>
    </v-main>
    <base-footer />
  </v-app>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import BaseFooter from "@/components/BaseFooter";
import BaseSnackbar from "@/components/BaseSnackbar";
export default {
  name: "DefaultLayout",
  components: { BaseHeader, BaseFooter, BaseSnackbar },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: $body-bg;
}
</style>