<template>
  <div>
    <div class="text-capitalize subtitle-1 mb-2">{{ label }} :</div>
    <v-file-input
      :placeholder="placeholder"
      accept="image/*"
      counter
      small-chips
      outlined
      dense
      prepend-inner-icon="mdi-paperclip"
      :rules="rules"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="fileName"
    >
    </v-file-input>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
      required: true,
      description: "Input label",
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
      description: "Input placeholder",
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
      description: "Accepts multiple files",
    },
    rules: {
      type: Array,
      default: () => [],
      required: true,
      description: "Validation rules on input",
    },
    fileName: {
      type: String,
      default: null,
      required: true
    },
  },
};
</script>

<style lang="scss">
.v-input__prepend-outer {
  display: none !important;
}

.v-file-input__text--placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
</style>
