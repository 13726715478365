<template>
  <base-table
    v-model="recentOrders.selected"
    :headers="recentOrders.headers"
    :items="itemsWithIndex"
    :search="recentOrders.search"
    show-select
    item-key="orderId"
    :page.sync="page"
    :items-per-page="itemsPerPage"
  >
    <!-- header -->

    <template v-slot:headerTop>
      <base-button title="view all" iconLeft="mdi-eye" to="/orders" />
      <v-spacer></v-spacer>
      <div :class="$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''">
        <v-text-field
          v-model="recentOrders.search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          clearable
          outlined
          hide-details
          dense
        ></v-text-field>
      </div>
    </template>

    <!-- items -->

    <template v-slot:[`item.name`]="{ item }">
      <span class="text-capitalize">{{ item.name }}</span>
    </template>

    <template v-slot:[`item.quantity`]="{ item }">
      <span>{{ item.quantity + " dozen(s)" }}</span>
    </template>

    <template v-slot:[`item.total`]="{ item }" ]>
      <span>{{ "&#8377; " + item.total }}</span>
    </template>

    <template v-slot:[`item.paymentStatus`]="{ item }">
      <base-chip
        v-if="item.paymentStatus === 'paid'"
        color="success"
        :title="item.paymentStatus"
      />
      <base-chip
        v-if="item.paymentStatus === 'pending'"
        color="warning"
        :title="item.paymentStatus"
      />

      <base-chip
        v-if="item.paymentStatus === 'refund'"
        color="error"
        :title="item.paymentStatus"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex">
        <base-icon icon="mdi-eye" small class="mr-4" @click="viewOrder(item)" />

        <span
          v-if="
            item.paymentStatus === 'paid' || item.paymentStatus === 'pending'
          "
        >
          <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{ on }">
              <base-icon icon="mdi-check" small class="mr-4" v-on="on" />
            </template>

            <v-card>
              <v-card-title class="pa-4 headline grey lighten-2">
                Order confirmation
              </v-card-title>

              <v-card-text class="pa-4">
                Are you sure you want to accept this order?
              </v-card-text>
              <v-card-actions class="pa-4 pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="dialog = false">
                  No
                </v-btn>
                <v-btn color="primary" @click="dialog = false">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{ on }">
              <base-icon icon="mdi-close" small class="mr-4" v-on="on" />
            </template>

            <v-card>
              <v-card-title class="pa-4 headline grey lighten-2">
                Cancel order confirmation
              </v-card-title>

              <v-card-text class="pa-4">
                Are you sure you want to cancel this order?
              </v-card-text>
              <v-card-actions class="pa-4 pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="dialog = false">
                  No
                </v-btn>
                <v-btn color="primary" @click="dialog = false">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
      </div>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import BaseButton from "@/components/BaseButton";
import BaseChip from "@/components/BaseChip";
import BaseIcon from "@/components/BaseIcon";

export default {
  components: { BaseTable, BaseButton, BaseChip, BaseIcon },

  data() {
    return {
      dialog: false,
      page: 1,
      itemsPerPage: 10,
      pageLength: 5,
      recentOrders: {
        selected: [],
        search: "",
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "srNo",
          },
          { text: "Created At", value: "createdAt" },
          { text: "Order ID", value: "orderId" },
          { text: "Name", value: "name" },
          { text: "Quantity", value: "quantity" },
          { text: "Total", value: "total" },
          { text: "Payment Method", value: "paymentMethod" },
          { text: "Payment Status", value: "paymentStatus" },
          { text: "Actions", value: "actions" },
        ],
        items: [
          {
            createdAt: "01/01/2021",
            orderId: "#001",
            name: "manohar anthony",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid",
          },
          {
            createdAt: "01/01/2021",
            orderId: "#002",
            name: "joe james",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending",
          },
          {
            createdAt: "02/01/2021",
            orderId: "#003",
            name: "john walsh",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid",
          },
          {
            createdAt: "03/01/2021",
            orderId: "#004",
            name: "Bob Herm",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending",
          },
          {
            createdAt: "03/01/2021",
            orderId: "#005",
            name: "James Houston",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "refund",
          },
        ],
      },
    };
  },

  computed: {
    itemsWithIndex() {
      return this.recentOrders.items.map((items, srNo) => ({
        ...items,
        srNo: srNo + 1,
      }));
    },
  },
};
</script>

<style></style>
