var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-table',{attrs:{"headers":_vm.membersTable.headers,"items":_vm.itemsWithIndex,"search":_vm.membersTable.search,"show-select":"","item-key":"name","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"headerTop",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('base-button',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"title":"role","outlined":"","iconLeft":"mdi-filter-variant"}},'base-button',attrs,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("All")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Curators")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Associates")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Select Writers")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Regular Writers")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('base-button',_vm._g(_vm._b({attrs:{"title":"status","outlined":"","iconLeft":"mdi-filter-variant"}},'base-button',attrs,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("All")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Active")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Inactive")])],1)],1)],1),_c('v-spacer'),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.membersTable.search),callback:function ($$v) {_vm.$set(_vm.membersTable, "search", $$v)},expression:"membersTable.search"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.city))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('base-chip',{attrs:{"color":"primary","title":item.status}}):_vm._e(),(item.status === 'inactive')?_c('base-chip',{attrs:{"color":"secondary","title":item.status}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('base-icon',{staticClass:"mr-4",attrs:{"icon":"mdi-eye","small":""},on:{"click":function($event){return _vm.viewItem()}}}),_c('base-icon',{staticClass:"mr-4",attrs:{"icon":"mdi-pencil","small":""},on:{"click":function($event){return _vm.editItem(item)}}}),_c('base-icon',{attrs:{"icon":"mdi-delete","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}})],1)]}}],null,true),model:{value:(_vm.membersTable.selected),callback:function ($$v) {_vm.$set(_vm.membersTable, "selected", $$v)},expression:"membersTable.selected"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('base-pagination',{attrs:{"length":_vm.pageLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }