<template>
  <base-section title="create order">
    <base-card class="pa-4">
      <v-form ref="createOrderForm" v-model="createOrderValid" lazy-validation>
        <v-card-text>
          <!-- <div class="order-id">
            <base-input
              v-model="createOrderForm._id"
              label="Order ID"
              placeholder="Enter order ID"
              type="number"
              min="0"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||
              event.charCode == 44 || event.charCode == 0"
              :rules="rules.orderId"
            />
          </div> -->
          <div
            class="billing-name"
            v-if="
              createOrderForm &&
                createOrderForm.userId &&
                createOrderForm.userId.name
            "
          >
            <base-input
              v-model="createOrderForm.userId.name"
              label="Billing name"
              placeholder="Enter billing name"
              :rules="rules.name"
              readonly
            />
          </div>

          <div class="order-quantity">
            <base-input
              v-model="createOrderForm.totalOrderDozen"
              label="Order quantity"
              placeholder="Enter order quantity"
              type="number"
              min="0"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||
              event.charCode == 44 || event.charCode == 0"
              :rules="rules.quantity"
              readonly
            />
          </div>

          <div class="order-total">
            <base-input
              v-model="createOrderForm.totalOrderAmount"
              label="order total"
              placeholder="Enter order total"
              type="number"
              min="0"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||
              event.charCode == 44 || event.charCode == 0"
              :rules="rules.total"
              readonly
            />
          </div>
          <div class="payment-status">
            <base-select
              item-text="status"
              label="payment status"
              placeholder="Select payment status"
              :items="statuscies"
              :orderStatus="createOrderForm.status"
              :rules="rules.paymentStatus"
              @statusChanged="changeStatus"
              :readonly="
                createOrderForm.status == 'Cancelled' ||
                createOrderForm.status == 'Completed'
                  ? true
                  : false
              "
            />
          </div>
          <div
            class="rejectionReason"
            v-if="createOrderForm.status == 'Rejected'"
          >
            <base-textarea
              v-model="createOrderForm.rejectionReason"
              label="Rejection Reason"
              placeholder="Enter Rejection Reason"
              counter="100"
              :rules="rules.comments"
            />
          </div>
          <div class="address">
            <base-textarea
              v-model="createOrderForm.orderAddress"
              label="Order Address"
              placeholder="Enter Order Address"
              counter="2000"
              :rules="rules.comments"
              readonly
            />
          </div>
          <!-- <div class="order-commments">
            <base-textarea
              v-model="createOrderForm.comments"
              label="Order comments (optional)"
              placeholder="Enter product description"
              counter="2000"
              :rules="rules.comments"
            />
          </div> -->
        </v-card-text>

        <v-card-actions
          class="pa-4 pt-0"
          v-if="createOrderForm.status == 'Pending'"
        >
          <v-spacer />
          <base-button
            title="cancel"
            color="error"
            large
            to="/orders"
            class="mr-2"
          />
          <base-button
            title="Save"
            color="success"
            large
            @click="acceptRejectOrder"
          />
        </v-card-actions>
      </v-form>
    </base-card>
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import BaseCard from "@/components/BaseCard";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import BaseTextarea from "@/components/BaseTextarea";
import BaseSelect from "@/components/BaseSelect";
export default {
  name: "create-order",
  components: {
    BaseSection,
    BaseCard,
    BaseInput,
    BaseTextarea,
    BaseButton,
    BaseSelect
  },
  data() {
    return {
      title: "create order",
      createOrderValid: true,
      createOrderForm: {
        orderId: "",
        name: "",
        quantity: "",
        total: "",
        status: ""
      },
      statuscies: [
        { status: "Pending" },
        { status: "InProcess" },
        { status: "Delivered" },
        { status: "Completed" },
        { status: "Rejected" },
        { status: "Cancelled" }
      ],
      rules: {
        orderId: [v => !!v || "Order ID is required"],
        name: [v => !!v || "Billing name is required"],
        quantity: [v => !!v || "Order quantity is required"],
        total: [v => !!v || "Order total is required"],
        paymentStatus: [v => !!v || "Payment status is required"]
      }
    };
  },
  methods: {
    createOrder() {
      if (this.$refs.createOrderForm.validate()) {
        this.$store.commit("snackbar/SET_OPEN", {
          text: "Order created successfully",
          color: "success"
        });
        this.$router.push("/orders");
      } else {
        this.$store.commit("snackbar/SET_OPEN", {
          text: "Something went wrong while creating order",
          color: "error"
        });
      }
    },
    changeStatus(data) {
      console.log(data);
      this.createOrderForm.status = data;
    },
    async acceptRejectOrder() {
      if (this.$route.params.orderId) {
        console.log("fghbjntrfyguhi", this.createOrderForm);
        let sendObj = {};
        if (this.createOrderForm.status == "Cancel") {
          sendObj["rejectionReason"] = this.createOrderForm.rejectionReason;
        }
        sendObj = {
          status: this.createOrderForm.status,
          orderId: this.createOrderForm._id
        };
        this.createOrderForm.orderId = this.$route.params.orderId;
        let updateProductData = await this.$store.dispatch(
          "order/updateOrder",
          this.createOrderForm
        );
        if (
          updateProductData &&
          updateProductData.data &&
          updateProductData.status == 200
        ) {
          this.$store.commit("snackbar/SET_OPEN", {
            text: "Order Status Changed successfully",
            color: "success"
          });
          this.$router.push("/orders");
        } else {
          this.$store.commit("snackbar/SET_OPEN", {
            text: updateProductData.data,
            color: "fail"
          });
          // this.$router.push("/orders");
        }
      }
      // else {
      //   let saveProductData = await this.$store.dispatch(
      //     "product/saveProduct",
      //     data
      //   );
      //   if (
      //     saveProductData &&
      //     saveProductData.data &&
      //     saveProductData.status == 200
      //   ) {
      //     this.$store.commit("snackbar/SET_OPEN", {
      //       text: "Order Status successfully",
      //       color: "success"
      //     });
      //     this.$router.push("/orders");
      //   } else {
      //     this.$store.commit("snackbar/SET_OPEN", {
      //       text: "Something Went Wrong",
      //       color: "fail"
      //     });
      //   }
      // }
    }
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.title);
  },
  async created() {
    if (this.$route.params.orderId) {
      let orderObj = await this.$store.dispatch(
        "order/getOneOrder",
        this.$route.params
      );
      if (orderObj && orderObj.data && orderObj.status == 200) {
        this.createOrderForm = orderObj.data;
        this.title = "create order";
        this.$store.commit("SET_TITLE", this.title);
        this.updateorder = true;
      }
    }
  }
};
</script>

<style></style>
