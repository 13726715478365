<template>
  <div>
    <!-- app bar -->
    <v-app-bar color="primary" height="70" dark app>
      <v-btn icon @click="drawer = !drawer">
        <v-icon>mdi-{{ `${drawer ? "close" : "menu"}` }}</v-icon>
      </v-btn>
      <!-- <v-btn v-if="drawer" icon class="mr-2" @click="mini = !mini">
        <v-icon>mdi-chevron-{{ `${mini ? "right" : "left"}` }}</v-icon>
      </v-btn> -->
      <v-toolbar-title class="text-capitalize">{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-menu offset-y left transition="slide-y-transition">
        <template
          v-slot:activator="{ on, attrs }"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-btn class="mr-2" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in notifications" :key="index">
              <v-list-item-icon class="mr-4"
                ><v-icon>mdi-bell-outline</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>{{ item.notification }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu> -->

      <v-menu offset-y left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-avatar color="grey lighten-1" v-on="on" class="mr-2">
            <span class="font-weight-bold">
              <img alt="Avatar" :src="profile.nameImage"
            /></span>
          </v-avatar>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in account" :key="index">
              <v-list-item-icon class="mr-4"
                ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <span v-if="$vuetify.breakpoint.smAndUp">Hi, {{ profile.name }}</span>
    </v-app-bar>

    <!-- drawer start -->
    <v-navigation-drawer v-model="drawer" :mini-variant="mini" app>
      <v-toolbar color="primary" dark height="70">
        <!-- <img :src="computeLogo" alt="" height="26" /> -->
        <v-toolbar-title class="ml-0 pl-3">Mumbai Mango</v-toolbar-title>
      </v-toolbar>
      <v-list>
        <template v-for="item in sidebarMenu">
          <template v-if="item.subMenu">
            <v-list-group :key="item.title" no-action>
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="item in item.subMenu"
                :key="item.title"
                :to="item.link"
                class="pl-8"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>

          <template v-else>
            <v-list-item :key="item.title" :to="item.link">
              <v-list-item-icon class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>

      <template v-slot:append>
        <div class="d-flex">
          <v-list-item class="grey lighten-3">
            <v-list-item-title v-if="!mini">Minimize sidebar</v-list-item-title>
            <v-list-item-icon class="mx-0 ml-auto" @click="mini = !mini">
              <v-icon
                >mdi-arrow-collapse-{{ `${mini ? "right" : "left"}` }}</v-icon
              >
            </v-list-item-icon>
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import computeLogo from "@/assets/logo/tlu-logo.png";
import { mapState } from "vuex";
export default {
  data() {
    return {
      // computeLogo,
      drawer: true,
      mini: false
    };
  },
  computed: {
    // title(){
    //   return this.$store.state.dashboard.title
    // },
    // ...mapState({
    //   title: state => state.dashboard.title
    // }),

    // title(){
    //   return this.$store.state.title
    // },
    ...mapState(["title", "sidebarMenu"]),

    notifications() {
      return this.$store.state.notifications.notifications;
    },
    account() {
      return this.$store.state.account;
    },
    profile() {
      return this.$store.state.auth.adminProfile;
    }
  },
  methods: {
    logout() {
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
