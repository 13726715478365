export default {
  state: {
    notifications: [
      { notification: "lorem ipsum" },
      { notification: "lorem ipsum lorem ipsum" },
      { notification: "lorem ipsum lorem ipsumlorem ipsum" },
      { notification: "lorem ipsu m lorem ipsum" },
      { notification: "lorem ipsum lorem" },
      { notification: "lorem ipsum lorem ipsum" },
    ],
  },
};
