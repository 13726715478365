import service from "../../service/apiService";
export default {
  namespaced: true,
  state: {
    allProductsArray: [],
    oneProductObj: {}
  },
  mutations: {
    SET_ALL_PRODUCTS_DATA(state, value) {
      state.allProductsArray = value;
    },
    SET_ONE_PRODUCTS_DATA(state, value) {
      state.oneProductObj = value;
    }
  },
  actions: {
    async saveProduct({ commit }, data) {
      try {
        data.fileName=data.fileName.name
        console.log("commitcommit", commit);
        let saveProductData = await service.apiCallWithData(
          "/Product/saveProduct",
          data
        );
        console.log("saveProductData saveProductData", saveProductData);
        return saveProductData;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getAllProduct({ commit }) {
      try {
        console.log("commitcommit", commit);
        let getAllProductData = await service.apiCallWithHeaderWithoutData(
          "/Product/getAllProductByAdmin"
        );
        console.log("getAllProductData getAllProductData", getAllProductData);
        if (
          getAllProductData &&
          getAllProductData.data &&
          getAllProductData.status == 200
        ) {
          commit("SET_ALL_PRODUCTS_DATA", getAllProductData.data);
        } else {
          commit("SET_ALL_PRODUCTS_DATA", []);
        }
        // return getAllProductData;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getOneProduct({ commit }, data) {
      try {
        console.log("commitcommit", commit);
        let getOneProductData = await service.apiCallWithHeaderWithoutData(
          `/Product/getOneProduct/${data.productId}`
        );
        console.log("getOneProductData getOneProductData", getOneProductData);
        // if (
        //   getOneProductData &&
        //   getOneProductData.data &&
        //   getOneProductData.status == 200
        // ) {
        //   commit("SET_ONE_PRODUCTS_DATA", getOneProductData.data);
        // } else {
        //   commit("SET_ONE_PRODUCTS_DATA", {});
        // }
        return getOneProductData;
      } catch (error) {
        console.log("error", error);
      }
    },
    async updateProduct({ commit }, data) {
      try {
        data.fileName=data.fileName.name
        console.log("commitcommit", commit);
        let updateProductData = await service.apiCallWithHeaderWithData(
          "/Product/updateProduct",
          data
        );
        console.log("updateProductData updateProductData", updateProductData);
        return updateProductData;
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
