<template>
  <base-card ripple hover>
    <v-row no-gutters class="text-center" align="center" justify="center">
      <v-col cols="6">
        <v-card-text>
          <base-icon size="60" :color="color" :icon="name" />
        </v-card-text>
      </v-col>
      <v-col cols="6" class="rounded-r-lg" :class="countClasses">
        <v-card-text class="white--text py-8">
          <div class="title">{{ count }}</div>
          <div class="subtitle-1">followers</div>
        </v-card-text>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import BaseIcon from "@/components/BaseIcon";
export default {
  name: "social-media-card",
  components: { BaseCard, BaseIcon },
  props: ["name", "count", "color", "countClasses"],
};
</script>

<style></style>
