var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{attrs:{"title":"create order"}},[_c('base-card',{staticClass:"pa-4"},[_c('v-form',{ref:"createOrderForm",attrs:{"lazy-validation":""},model:{value:(_vm.createOrderValid),callback:function ($$v) {_vm.createOrderValid=$$v},expression:"createOrderValid"}},[_c('v-card-text',[(
            _vm.createOrderForm &&
              _vm.createOrderForm.userId &&
              _vm.createOrderForm.userId.name
          )?_c('div',{staticClass:"billing-name"},[_c('base-input',{attrs:{"label":"Billing name","placeholder":"Enter billing name","rules":_vm.rules.name,"readonly":""},model:{value:(_vm.createOrderForm.userId.name),callback:function ($$v) {_vm.$set(_vm.createOrderForm.userId, "name", $$v)},expression:"createOrderForm.userId.name"}})],1):_vm._e(),_c('div',{staticClass:"order-quantity"},[_c('base-input',{attrs:{"label":"Order quantity","placeholder":"Enter order quantity","type":"number","min":"0","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57) ||\n            event.charCode == 44 || event.charCode == 0","rules":_vm.rules.quantity,"readonly":""},model:{value:(_vm.createOrderForm.totalOrderDozen),callback:function ($$v) {_vm.$set(_vm.createOrderForm, "totalOrderDozen", $$v)},expression:"createOrderForm.totalOrderDozen"}})],1),_c('div',{staticClass:"order-total"},[_c('base-input',{attrs:{"label":"order total","placeholder":"Enter order total","type":"number","min":"0","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57) ||\n            event.charCode == 44 || event.charCode == 0","rules":_vm.rules.total,"readonly":""},model:{value:(_vm.createOrderForm.totalOrderAmount),callback:function ($$v) {_vm.$set(_vm.createOrderForm, "totalOrderAmount", $$v)},expression:"createOrderForm.totalOrderAmount"}})],1),_c('div',{staticClass:"payment-status"},[_c('base-select',{attrs:{"item-text":"status","label":"payment status","placeholder":"Select payment status","items":_vm.statuscies,"orderStatus":_vm.createOrderForm.status,"rules":_vm.rules.paymentStatus,"readonly":_vm.createOrderForm.status == 'Cancelled' ||
              _vm.createOrderForm.status == 'Completed'
                ? true
                : false},on:{"statusChanged":_vm.changeStatus}})],1),(_vm.createOrderForm.status == 'Rejected')?_c('div',{staticClass:"rejectionReason"},[_c('base-textarea',{attrs:{"label":"Rejection Reason","placeholder":"Enter Rejection Reason","counter":"100","rules":_vm.rules.comments},model:{value:(_vm.createOrderForm.rejectionReason),callback:function ($$v) {_vm.$set(_vm.createOrderForm, "rejectionReason", $$v)},expression:"createOrderForm.rejectionReason"}})],1):_vm._e(),_c('div',{staticClass:"address"},[_c('base-textarea',{attrs:{"label":"Order Address","placeholder":"Enter Order Address","counter":"2000","rules":_vm.rules.comments,"readonly":""},model:{value:(_vm.createOrderForm.orderAddress),callback:function ($$v) {_vm.$set(_vm.createOrderForm, "orderAddress", $$v)},expression:"createOrderForm.orderAddress"}})],1)]),(_vm.createOrderForm.status == 'Pending')?_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('base-button',{staticClass:"mr-2",attrs:{"title":"cancel","color":"error","large":"","to":"/orders"}}),_c('base-button',{attrs:{"title":"Save","color":"success","large":""},on:{"click":_vm.acceptRejectOrder}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }