var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"headers":_vm.recentOrders.headers,"items":_vm.itemsWithIndex,"search":_vm.recentOrders.search,"show-select":"","item-key":"orderId","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"headerTop",fn:function(){return [_c('base-button',{attrs:{"title":"view all","iconLeft":"mdi-eye","to":"/orders"}}),_c('v-spacer'),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.recentOrders.search),callback:function ($$v) {_vm.$set(_vm.recentOrders, "search", $$v)},expression:"recentOrders.search"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.quantity + " dozen(s)"))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("₹ " + item.total))])]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [(item.paymentStatus === 'paid')?_c('base-chip',{attrs:{"color":"success","title":item.paymentStatus}}):_vm._e(),(item.paymentStatus === 'pending')?_c('base-chip',{attrs:{"color":"warning","title":item.paymentStatus}}):_vm._e(),(item.paymentStatus === 'refund')?_c('base-chip',{attrs:{"color":"error","title":item.paymentStatus}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('base-icon',{staticClass:"mr-4",attrs:{"icon":"mdi-eye","small":""},on:{"click":function($event){return _vm.viewOrder(item)}}}),(
          item.paymentStatus === 'paid' || item.paymentStatus === 'pending'
        )?_c('span',[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-check","small":""}},on))]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4 headline grey lighten-2"},[_vm._v(" Order confirmation ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you want to accept this order? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-close","small":""}},on))]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4 headline grey lighten-2"},[_vm._v(" Cancel order confirmation ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you want to cancel this order? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Yes ")])],1)],1)],1)],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.recentOrders.selected),callback:function ($$v) {_vm.$set(_vm.recentOrders, "selected", $$v)},expression:"recentOrders.selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }