<template>
  <base-card>
    <v-card-title>
      <slot name="headerTop" />
    </v-card-title>

    <v-divider />

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :show-select="showSelect"
      :item-key="itemKey"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      mobile-breakpoint
      class="mb-8"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!-- https://stackoverflow.com/questions/59609361/vue-pass-down-slots-to-child-components -->
      <!-- https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component -->
      <!-- https://github.com/vuejs/rfcs/blob/master/active-rfcs/0001-new-slot-syntax.md -->
      <!-- https://stackoverflow.com/questions/46380574/how-to-display-the-index-of-an-array-using-the-vuetify-data-table -->

      <!-- https://dev.to/onurelibol/creating-custom-components-with-vuetify-inheriting-props-events-slots-in-composition-api-56ef -->

      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- <template slot="xxx"><slot name="xxx" /></template> -->

      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-data-table>
  </base-card>
</template>

<script>
import BaseCard from "@/components/BaseCard";
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
      required: true,
      description: "Array of Headers",
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
      description: "Array of items",
    },
    search: {
      type: String,
      default: "",
      required: false,
      description: "Search items",
    },
    showSelect: {
      type: Boolean,
      default: false,
      required: false,
      description: "Show whether or not the item is selected",
    },
    itemKey: {
      type: String,
      default: "",
      required: false,
      description: "Item key",
    },
    itemsPerPage: {
      type: Number,
      default: 0,
      required: false,
      description: "Items per page",
    },
    page: { type: Number, default: 0, required: false, description: "Page" },
  },
  components: { BaseCard },
  // computed: {
  //   itemsWithIndex() {
  //     return this.items.map((items, index) => ({
  //       ...items,
  //       index: index + 1
  //     }))
  //   }
  // }
};
</script>

<style></style>
