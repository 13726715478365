<template>
  <div>
    <base-table
      v-model="membersTable.selected"
      :headers="membersTable.headers"
      :items="itemsWithIndex"
      :search="membersTable.search"
      show-select
      item-key="name"
      :page.sync="page"
      :items-per-page="itemsPerPage"
    >
      <!-- header -->

      <template v-slot:headerTop>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <base-button
              title="role"
              outlined
              iconLeft="mdi-filter-variant"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-list dense>
            <v-list-item link>
              <v-list-item-title>All</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Curators</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Associates</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Select Writers</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title>Regular Writers</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <base-button
              title="status"
              outlined
              iconLeft="mdi-filter-variant"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-list dense>
            <v-list-item link>
              <v-list-item-title>All</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Active</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Inactive</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''">
          <v-text-field
            v-model="membersTable.search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
            outlined
            hide-details
            dense
          ></v-text-field>
        </div>
      </template>

      <!-- items -->

      <template v-slot:[`item.name`]="{ item }">
        <span class="text-capitalize">{{ item.name }}</span>
      </template>

      <template v-slot:[`item.city`]="{ item }">
        <span class="text-capitalize">{{ item.city }}</span>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <span class="text-capitalize">{{ item.role }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <!-- <base-chip :color="{ primary : item.status === 'inactive' }" :title="item.status" /> -->
        <base-chip
          v-if="item.status === 'active'"
          color="primary"
          :title="item.status"
        />
        <base-chip
          v-if="item.status === 'inactive'"
          color="secondary"
          :title="item.status"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <base-icon
            icon="mdi-eye"
            small
            class="mr-4"
            @click="viewItem()"
          />
          <base-icon
            icon="mdi-pencil"
            small
            class="mr-4"
            @click="editItem(item)"
          />
          <base-icon icon="mdi-delete" small @click="deleteItem(item)" />
        </div>
      </template>
    </base-table>

    <div class="d-flex justify-end">
      <base-pagination v-model="page" :length="pageLength" />
    </div>
  </div>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import BasePagination from "@/components/BasePagination";
import BaseButton from "@/components/BaseButton";
import BaseChip from "@/components/BaseChip";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "members-table",
  components: { BaseTable, BasePagination, BaseButton, BaseChip, BaseIcon },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      pageLength: 5,
      membersTable: {
        selected: [],
        search: "",
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "srNo",
          },
          { text: "Created At", value: "createdAt" },
          { text: "Name", value: "name" },
          { text: "Email", value: "email" },
          { text: "Contact", value: "mobileNo" },
          // { text: "City", value: "city" },
          { text: "Role", value: "role" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions" },
        ],
        items: [
          {
            createdAt: "01/01/2021",
            name: "manohar anthony",
            email: "manohar.anthony@gmail.com",
            mobileNo: "9876543210",
            city: "mumbai",
            role: "select writer",
            status: "active",
          },
          {
            createdAt: "01/01/2021",
            name: "joe james",
            email: "joe.james@gmail.com",
            mobileNo: "9876543210",
            city: "mumbai",
            role: "select writer",
            status: "active",
          },
          {
            createdAt: "02/01/2021",
            name: "john walsh",
            email: "john.walsh@gmail.com",
            mobileNo: "9876543210",
            city: "mumbai",
            role: "regular writer",
            status: "active",
          },
          {
            createdAt: "03/01/2021",
            name: "Bob Herm",
            email: "bob.herm@gmail.com",
            mobileNo: "9876543210",
            city: "pune",
            role: "curator",
            status: "active",
          },
          {
            createdAt: "03/01/2021",
            name: "James Houston",
            email: "james.houston@gmail.com",
            mobileNo: "9876543210",
            city: "hyderabad",
            role: "select writer",
            status: "inactive",
          },
          {
            createdAt: "03/01/2021",
            name: "Prabhakar Linwood",
            email: "prabhakar.linwood@gmail.com",
            mobileNo: "9876543210",
            city: "mumbai",
            role: "curator",
            status: "active",
          },
          {
            createdAt: "04/01/2021",
            name: "Jelly bean",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "05/01/2021",
            name: "Kaui Ignace",
            email: "kaui.ignace@gmail.com",
            mobileNo: "9876543210",
            city: "Mumbai",
            role: "select Writer",
            status: "inactive",
          },
          {
            createdAt: "05/01/2021",
            name: "Esperanza Susanne",
            email: "esperanza.susanne@gmail.com",
            mobileNo: "9876543210",
            city: "pune",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "06/01/2021",
            name: "Christian Birgitte",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "06/01/2021",
            name: "Meral Elias",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "07/01/2021",
            name: "Deep Pau",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "07/01/2021",
            name: "Sebastiana Hani",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "07/01/2021",
            name: "Marciano Oihana",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
          {
            createdAt: "07/01/2021",
            name: "Brigid Ankur",
            email: "jelly.bean@gmail.com",
            mobileNo: "9876543210",
            city: "Banglore",
            role: "Regular Writer",
            status: "active",
          },
        ],
      },
    };
  },
  computed: {
    itemsWithIndex() {
      return this.membersTable.items.map((items, srNo) => ({
        ...items,
        srNo: srNo + 1,
      }));
    },
  },
  methods: {
    viewItem() {
      this.$router.push('/member-details')
    },
    editItem() {
      console.log("editItem console");
    },
    deleteItem() {
      console.log("editItem console");
    },
  },
};
</script>
