<template>
  <v-chip class="text-capitalize" :color="color" small>
    {{ title }}
  </v-chip>
</template>

<script>
export default {
  name: "base-chip",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
      description: "Title of the chip",
    },
    color: {
      type: String,
      default: "success",
      required: false,
      description: "Color of the chip",
    },
    close: {
      type: Boolean,
      default: false,
      required: false,
      description: "Adds remove button",
    },
  },
};
</script>

<style></style>
