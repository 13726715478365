<template>
  <base-card ripple hover>
    <v-card-title class="font-weight-bold" v-html="number"></v-card-title>
    <v-card-subtitle class="text-capitalize">{{ title }} </v-card-subtitle>
    <v-card-text>
      <span class="success--text">
        <v-icon small :color="upDown > 1 ? 'success' : 'error'" class="mt-n1"
          >mdi-arrow-up-bold</v-icon
        >
        {{ upDown }}</span
      >
      up since last week</v-card-text
    >
    <div :class="borderColor" class="rounded-b-lg pa-1"></div>
  </base-card>
</template>

<script>
import BaseCard from "@/components/BaseCard";

export default {
  name: "stats-card",
  props: ["title", "number", "upDown", "borderColor"],
  components: { BaseCard },
};
</script>
