var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"headers":_vm.orders.headers,"items":_vm.allOrders,"search":_vm.orders.search,"show-select":"","item-key":"orderId","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"headerTop",fn:function(){return [_c('base-button',{staticClass:"mr-2",attrs:{"title":"Add","iconLeft":"mdi-plus","to":"/create-order"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('base-button',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"title":"status","outlined":"","iconLeft":"mdi-filter-variant"}},'base-button',attrs,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("All")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Paid")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Refund")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Pending")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('base-button',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"title":"method","outlined":"","iconLeft":"mdi-filter-variant"}},'base-button',attrs,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("All")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Online")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Offline")])],1)],1)],1),_c('v-spacer'),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.orders.search),callback:function ($$v) {_vm.$set(_vm.orders, "search", $$v)},expression:"orders.search"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item && item.userId && item.userId.name)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.userId.name))]):_c('span',[_vm._v("-")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.totalOrderDozen + " dozen(s)"))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("₹ " + item.totalOrderAmount))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Completed')?_c('base-chip',{attrs:{"color":"success","title":item.status}}):_vm._e(),(item.status === 'Pending')?_c('base-chip',{attrs:{"color":"warning","title":item.status}}):_vm._e(),(item.status === 'Cancelled')?_c('base-chip',{attrs:{"color":"error","title":item.status}}):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item && item.createdAt)?_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString()))]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('base-icon',{staticClass:"mr-4",attrs:{"icon":"mdi-eye","small":""},on:{"click":function($event){return _vm.viewOrder(item._id)}}}),(item.status === 'Pending')?_c('span',[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-check","small":""},on:{"click":function($event){return _vm.openModel(item._id)}}},on))]}}],null,true),model:{value:(_vm.acceptDialog[((item._id) + "-accept")]),callback:function ($$v) {_vm.$set(_vm.acceptDialog, ((item._id) + "-accept"), $$v)},expression:"acceptDialog[`${item._id}-accept`]"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4 headline grey lighten-2"},[_vm._v(" Order confirmation ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you want to Accept Order? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.acceptDialog = {}}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.acceptOrder({ orderId: item._id, status: 'Completed' })}}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-close","small":""}},on))]}}],null,true),model:{value:(_vm.acceptDialog[((item._id) + "-reject")]),callback:function ($$v) {_vm.$set(_vm.acceptDialog, ((item._id) + "-reject"), $$v)},expression:"acceptDialog[`${item._id}-reject`]"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4 headline grey lighten-2"},[_vm._v(" Cancel order confirmation ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you want to cancel this order? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.acceptDialog = {}}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.acceptOrder({ orderId: item._id, status: 'Cancelled' })}}},[_vm._v(" Yes ")])],1)],1)],1)],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.orders.selected),callback:function ($$v) {_vm.$set(_vm.orders, "selected", $$v)},expression:"orders.selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }