<template>
  <v-row>
    <v-col cols="12" md="3" sm="6">
      <stats-card
        title="total revenue"
        number="&#8377; 100000"
        upDown="2.26"
        borderColor="error"
      />
    </v-col>
    <v-col cols="12" md="3" sm="6">
      <stats-card
        title="total orders"
        number="123456"
        upDown="100"
        borderColor="info"
      />
    </v-col>
    <v-col cols="12" md="3" sm="6">
      <stats-card
        title="customers"
        number="9999"
        upDown="7"
        borderColor="success"
      />
    </v-col>
    <v-col cols="12" md="3" sm="6">
      <stats-card
        title="growth"
        number="&plus; 12.58"
        upDown="20"
        borderColor="warning"
      />
    </v-col>
  </v-row>
</template>

<script>
import StatsCard from "@/components/Dashboard/StatsCard";

export default {
  name: "stats-section",
  components: { StatsCard },
};
</script>

<style></style>
