<template>
  <base-section :title="title">
    <base-card class="pa-4">
      <v-form ref="createProductForm" v-model="createProductValid" lazy-validation>
        <v-card-text>
          <!-- <div class="product-id">
            <base-input
              v-model="createProductForm.id"
              label="Product ID"
              placeholder="Enter product ID"
              :rules="rules.id"
            />
          </div> -->

          <div class="product-name">
            <base-input v-model="createProductForm.name" label="Product name" placeholder="Enter product name"
              :rules="rules.name" />
          </div>

          <div class="product-description">
            <base-textarea v-model="createProductForm.description" label="Product description"
              placeholder="Enter product description" counter="2000" :rules="rules.description" />
          </div>

          <div class="product-price">
            <base-input v-model="createProductForm.amount" label="Product price" placeholder="Enter product price"
              :rules="rules.price" />
          </div>
          <div class="product-image">
            <base-file-input label="product image" :fileName="createProductForm.fileName" placeholder="Upload product image(s)" @change="fileInput" />
            <!-- :rules="rules.image" -->
          </div>
          <div class="payment-status">
            <base-select
              item-text="status"
              label="view status"
              placeholder="Select view"
              :orderStatus="createProductForm.enable"
              :items=[true,false]
              :rules="rules.status"
              @statusChanged="changeStatus"
            />
          </div>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer />
          <base-button title="cancel" color="error" large to="/products" class="mr-2" />
          <base-button :title="updateProduct ? 'Update' : 'Save'" color="success" large
            @click="saveProduct(createProductForm)" />
        </v-card-actions>
      </v-form>
    </base-card>
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import BaseCard from "@/components/BaseCard";
import BaseInput from "@/components/BaseInput";
import BaseTextarea from "@/components/BaseTextarea";
import BaseButton from "@/components/BaseButton";
import BaseFileInput from "@/components/BaseFileInput";
import BaseSelect from "@/components/BaseSelect";
export default {
  name: "create-product",
  components: {
    BaseSection,
    BaseCard,
    BaseInput,
    BaseTextarea,
    BaseButton,
    BaseFileInput,
    BaseSelect
  },
  data() {
    return {
      title: "Create Product",
      createProductValid: true,
      updateProduct: false,
      createProductForm: {
        // id: "",
        name: "",
        description: "",
        amount: "",
        fileName: null,
        fileContent: "",
        enable:true
      },
      rules: {
        // id: [v => !!v || "Product ID is required"],
        name: [v => !!v || "Product name is required"],
        description: [v => !!v || "Product description is required"],
        amount: [v => !!v || "Product price is required"],
        image: [v => !!v || "Product image(s) is required"],
        // status: [v => v || "status is required"]
      }
    };
  },
  methods: {
    fileInput(event) {
      console.log("fileInput fileInput", event)
      this.createProductForm.fileName = {name:event.name}
      
      let reader = new FileReader();
      //reads the binary data and encodes it as base64 data url
      reader.readAsDataURL(event);
      reader.onloadend = () => {
        //reader.result is the result of the reading in base64 string
        console.log("ERTYHUI", reader.result)
        this.createProductForm.fileContent = reader.result
      };
    },
    changeStatus(data) {
      console.log(data);
      this.createProductForm.enable = data;
    },
    async saveProduct(data) {
      console.log("saveProduct", data);
      if (this.$route.params.productId) {
        data.productId = this.$route.params.productId;
        let updateProductData = await this.$store.dispatch(
          "product/updateProduct",
          data
        );
        if (
          updateProductData &&
          updateProductData.data &&
          updateProductData.status == 200
        ) {
          this.$store.commit("snackbar/SET_OPEN", {
            text: "Product Updated successfully",
            color: "success"
          });
          this.$router.push("/products");
        } else {
          this.$store.commit("snackbar/SET_OPEN", {
            text: "Something Went Wrong",
            color: "fail"
          });
          this.$router.push("/products");
        }
      } else {
        let saveProductData = await this.$store.dispatch(
          "product/saveProduct",
          data
        );
        if (
          saveProductData &&
          saveProductData.data &&
          saveProductData.status == 200
        ) {
          this.$store.commit("snackbar/SET_OPEN", {
            text: "Product Created successfully",
            color: "success"
          });
          this.$router.push("/products");
        } else {
          this.$store.commit("snackbar/SET_OPEN", {
            text: "Something Went Wrong",
            color: "fail"
          });
        }
      }
      // if (this.$refs.createProductForm.validate()) {
      //   this.$store.commit("snackbar/SET_OPEN", {
      //     text: "Product created successfully",
      //     color: "success"
      //   });
      //   this.$router.push("/products");
      // }
    }
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.title);
  },
  async created() {
    console.log("SDFGHJKLWERTYU", this.$route);
    if (this.$route.params.productId) {
      let productObj = await this.$store.dispatch(
        "product/getOneProduct",
        this.$route.params
      );
      if (productObj && productObj.data && productObj.status == 200) {
        this.createProductForm = productObj.data;
        this.createProductForm.fileName={
          name:productObj.data.fileName
        }
        this.title = "Update Product";
        this.$store.commit("SET_TITLE", this.title);
        this.updateProduct = true;
      }
    }
  }
};
</script>

<style>

</style>
