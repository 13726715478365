<template>
  <v-row>
    <v-col cols="12" md="4" sm="4">
      <social-media-card
        name="mdi-facebook"
        count="100000"
        color="blue darken-4"
        count-classes="blue darken-4"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4">
      <social-media-card
        name="mdi-instagram"
        count="5000000"
        color="pink darken-3"
        count-classes="pink darken-3"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4">
      <social-media-card
        name="mdi-twitter"
        count="30000"
        color="blue"
        count-classes="blue"
      />
    </v-col>
  </v-row>
</template>

<script>
import SocialMediaCard from "@/components/Dashboard/SocialMediaCard";

export default {
  name: "social-media-section",
  components: { SocialMediaCard },
};
</script>

<style></style>
