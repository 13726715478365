<template>
  <v-footer color="primary" padless>
    <v-col class="py-1" cols="12">
      <div class="subtitle-2 text-right white--text">
        &copy; {{ new Date().getFullYear() }} — <strong>Manohar Anthony</strong>
      </div>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "base-footer",
};
</script>

<style></style>
