export default {
  namespaced: true,
  state: {
    model: false,
    text: "",
    color: "",
    timeout: 3000,
  },

  mutations: {
    SET_CLOSE(state) {
      state.model = false;
    },
    SET_MODEL(state, data) {
      state.model = data;
    },
    SET_OPEN(state, { text, color }) {
      state.model = true;
      state.color = color;
      state.text = text;
    },
  },

  getters: {
    getModel: (state) => state.model,
  },
};
