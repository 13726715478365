import Vue from "vue";
import VueRouter from "vue-router";

import AuthLayout from "../layouts/AuthLayout.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import ErrorLayout from "../layouts/ErrorLayout";

// import service from "../../service/apiService";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "login",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/Login.vue"),
        meta: {
          requiresAuth: false
        }
      }
      // {
      //   path: "/register",
      //   name: "register",
      //   component: () => import("../views/Auth/Register.vue"),
      // },
      // {
      //   path: "/forgot-password",
      //   name: "forgot-password",
      //   component: () => import("../views/Auth/ForgotPassword.vue")
      // },
      // {
      //   path: "/resend-link",
      //   name: "resend-link",
      //   component: () => import("../views/Auth/ResendLink.vue")
      // }
    ]
  },
  {
    path: "/",
    component: ErrorLayout,
    children: [
      // {
      //   path: "/about",
      //   name: "about",
      //   component: () => import("../views/About.vue"),
      // }
    ]
  },
  {
    path: "/",
    name: "DefaultLayout",
    component: DefaultLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: "/home",
      //   name: "home",
      //   component: () => import("../views/Home.vue")
      // },
      {
        path: "/members",
        name: "members",
        component: () => import("../views/Members.vue"),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: "/member-details",
      //   name: "member-details",
      //   component: () => import("../views/Members/MemberDetails.vue")
      // },
      {
        path: "/products",
        name: "products",
        component: () => import("../views/Products/Products.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/create-product/:productId?",
        name: "create-product",
        component: () => import("../views/Products/CreateProduct.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("../views/Orders/Orders.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/create-order/:orderId?",
        name: "create-order",
        component: () => import("../views/Orders/CreateOrder.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
  // {
  //   path: "/",
  //   name: "Home",
  //   meta: { layout: AuthLayout },
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   meta: { layout: DefaultLayout },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // {
  //   path: "/members",
  //   name: "Members",
  //   meta: { layout: DefaultLayout },
  //   component: () => import('../views/Members.vue')
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
