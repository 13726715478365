<template>
  <div>
    <base-section title="social media">
      <social-media-section />
    </base-section>
    <base-section title="stats">
      <stats-section />
    </base-section>
    <base-section title="recent orders">
      <recent-orders />
    </base-section>
  </div>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import SocialMediaSection from "@/components/Dashboard/SocialMediaSection";
import StatsSection from "@/components/Dashboard/StatsSection";
import RecentOrders from "@/components/Orders/RecentOrders";

export default {
  name: "dashboard",
  components: { BaseSection, SocialMediaSection, StatsSection, RecentOrders },
  data() {
    return {
      title: "dashboard"
    };
  },
  async created() {
    this.$store.commit("SET_TITLE", this.title);
    await this.$store.dispatch("auth/getOneUser", {});
  }
};
</script>

<style></style>
