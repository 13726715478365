<template>
  <v-pagination
    circle
    :length="length"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-pagination>
</template>

<script>
export default {
  name: "base-pagination",
  props: {
    length: { type: Number },
  },
};
</script>

<style lang="scss">
li + li {
  margin-top: 0;
}

.v-pagination__navigation,
.v-pagination__item {
  outline: none !important;
}
</style>
