<template>
  <base-section title="Products">
    <v-card rounded="lg">
      <v-card-title>
        <base-button
          class="mr-2"
          title="Add"
          iconLeft="mdi-plus"
          to="/create-product"
        />
        <base-button title="filter" outlined iconLeft="mdi-filter-variant" />
        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''">
          <v-text-field
            v-model="products.search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
            outlined
            hide-details
            dense
          ></v-text-field>
        </div>
      </v-card-title>

      <v-divider />
      <v-data-table
        :headers="products.headers"
        :items="allProducts"
        :search="products.search"
        item-key="_id"
        show-select
        hide-default-footer
        mobile-breakpoint
      >
        <template v-slot:[`item.srNo`]="{ index }">
          <span>{{ index + 1 }}</span>
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <!-- <span>{{ item.quantity }}</span> -->
          <div class="my-2" style="width: 100px">
            <v-img :src="item.image"></v-img>
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span class="text-capitalize">{{ item.name }}</span>
        </template>

        <template v-slot:[`item.amount`]="{ item }" ]>
          <span>{{ "&#8377; " + item.amount }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <!-- <base-icon
              icon="mdi-eye"
              small
              class="mr-4"
              @click="viewOrder(item)"
            /> -->

            <v-dialog width="400">
              <template v-slot:activator="{ on }">
                <base-icon
                  v-on="on"
                  icon="mdi-pencil"
                  small
                  class="mr-4"
                  @click="editProduct(item._id)"
                />
              </template>

              <!-- <v-card>
                <v-card-title class="pa-4 headline grey lighten-2">
                  Order confirmation
                </v-card-title>

                <v-card-text class="pa-4">
                  Are you sure you want to accept this order?
                </v-card-text>
                <v-card-actions class="pa-4 pt-0">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" outlined @click="dialog = false">
                    No
                  </v-btn>
                  <v-btn color="primary" @click="dialog = false">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card> -->
            </v-dialog>

            <v-dialog v-model="dialog" width="400">
              <template v-slot:activator="{ on }">
                <base-icon icon="mdi-close" small class="mr-4" v-on="on" />
              </template>

              <v-card>
                <v-card-title class="pa-4 headline grey lighten-2">
                  Cancel order confirmation
                </v-card-title>

                <v-card-text class="pa-4">
                  Are you sure you want to cancel this order?
                </v-card-text>
                <v-card-actions class="pa-4 pt-0">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" outlined @click="dialog = false">
                    No
                  </v-btn>
                  <v-btn color="primary" @click="dialog = false">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import BaseButton from "@/components/BaseButton";
// import BaseChip from "@/components/BaseChip";
import BaseIcon from "@/components/BaseIcon";

export default {
  components: { BaseSection, BaseButton, BaseIcon },

  data() {
    return {
      dialog: false,
      products: {
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "srNo"
          },
          // { text: "Created At", value: "createdAt" },
          // { text: "ID", value: "id" },
          { text: "Image", value: "image" },
          { text: "Name", value: "name" },
          { text: "Description", value: "description" },
          { text: "Price", value: "amount" },
          { text: "Actions", value: "actions" }
        ],
        items: [
          {
            id: 1,
            createdAt: "01/01/2021",
            name: "trainers",
            description: "Trainers in white",
            image:
              "https://sing-generator-node.herokuapp.com/assets/products/img1.jpg",
            price: "999"
          },
          {
            id: 2,
            createdAt: "01/01/2021",
            name: "boots",
            description: "Trainers in white",
            image:
              "https://sing-generator-node.herokuapp.com/assets/products/img4.jpg",
            price: "99"
          },
          {
            id: 3,
            createdAt: "01/01/2021",
            name: "flat sandals",
            description: "Trainers in white",
            image:
              "https://sing-generator-node.herokuapp.com/assets/products/img3.jpg",
            price: "599"
          },
          {
            id: 4,
            createdAt: "01/01/2021",
            name: "trainers",
            description: "Trainers in white",
            image:
              "https://sing-generator-node.herokuapp.com/assets/products/img4.jpg",
            price: "799"
          },
          {
            id: 5,
            createdAt: "01/01/2021",
            name: "boots",
            description: "Trainers in white",
            image:
              "https://sing-generator-node.herokuapp.com/assets/products/img1.jpg",
            price: "999"
          }
        ]
      }
    };
  },
  computed: {
    allProducts() {
      return this.$store.state.product.allProductsArray;
    }
  },
  methods: {
    editProduct(productId) {
      this.$router.push({ path: `/create-product/${productId}` });
    }
  },
  async created() {
    await this.$store.dispatch("product/getAllProduct");
  }
};
</script>

<style></style>
