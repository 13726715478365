import Vue from "vue";
import Vuex from "vuex";

// modules
import members from "./members";
import snackbar from "./snackbar";
import notifications from "./notifications";
import product from "./product";
import order from "./order";
import auth from "./auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    members,
    snackbar,
    notifications,
    product,
    order,
    auth
  },
  state: {
    title: "",
    sidebarMenu: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        link: "/dashboard"
      },
      {
        icon: "mdi-account",
        title: "Members",
        link: "/members"
      },
      {
        icon: "mdi-package-variant",
        title: "Products",
        link: "/products"
      },
      {
        icon: "mdi-format-list-bulleted",
        title: "Orders",
        link: "/orders"
      }
      // {
      //   icon: "mdi-plus",
      //   title: "Page 3",
      //   subMenu: [
      //     {
      //       icon: "mdi-minus",
      //       title: "Page 3 Subitem",
      //       link: "/home",
      //     },
      //   ],
      // },
    ],
    account: [
      {
        title: "my profile",
        icon: "mdi-account-outline"
      },
      {
        title: "change password",
        icon: "mdi-lock-reset"
      },
      {
        title: "notifications",
        icon: "mdi-bell-outline"
      }
    ]
  },
  mutations: {
    SET_TITLE(state, data) {
      state.title = data;
    }
  },
  actions: {},
  getters: {}
});
