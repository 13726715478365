export default {
  namespaced: true,
  state: {
    user: {
      avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
      name: "manohar anthony",
      email: "manohar@tlu.com",
      mobileNo: 9876543210,
      about: "Lorem ipsum dolor sit amet.",
      verified: true,
    },
  },
};
