<template>
  <base-table
    v-model="orders.selected"
    :headers="orders.headers"
    :items="allOrders"
    :search="orders.search"
    show-select
    item-key="orderId"
    :page.sync="page"
    :items-per-page="itemsPerPage"
  >
    <!-- header -->

    <template v-slot:headerTop>
      <base-button
        class="mr-2"
        title="Add"
        iconLeft="mdi-plus"
        to="/create-order"
      />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <base-button
            title="status"
            outlined
            iconLeft="mdi-filter-variant"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-list dense>
          <v-list-item link>
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title>Paid</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title>Refund</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title>Pending</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <base-button
            title="method"
            outlined
            iconLeft="mdi-filter-variant"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-list dense>
          <v-list-item link>
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title>Online</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title>Offline</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <div :class="$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''">
        <v-text-field
          v-model="orders.search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          clearable
          outlined
          hide-details
          dense
        ></v-text-field>
      </div>
    </template>

    <!-- items -->
     
    <template v-slot:[`item.name`]="{ item }">
      <span
        class="text-capitalize"
        v-if="item && item.userId && item.userId.name"
        >{{ item.userId.name }}</span
      >
      <span v-else>-</span>
    </template>

    <template v-slot:[`item.quantity`]="{ item }">
      <span>{{ item.totalOrderDozen + " dozen(s)" }}</span>
    </template>

    <template v-slot:[`item.total`]="{ item }" ]>
      <span>{{ "&#8377; " + item.totalOrderAmount }}</span>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <base-chip
        v-if="item.status === 'Completed'"
        color="success"
        :title="item.status"
      />
      <base-chip
        v-if="item.status === 'Pending'"
        color="warning"
        :title="item.status"
      />

      <base-chip
        v-if="item.status === 'Cancelled'"
        color="error"
        :title="item.status"
      />
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      <span
        class="text-center"
        v-if="item && item.createdAt"
        >{{ new Date(item.createdAt).toLocaleDateString()}}</span
      >
      <span v-else>-</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex">
        <base-icon
          icon="mdi-eye"
          small
          class="mr-4"
          @click="viewOrder(item._id)"
        />

        <span v-if="item.status === 'Pending'">
          <v-dialog v-model="acceptDialog[`${item._id}-accept`]" width="400">
            <template v-slot:activator="{ on }">
              <base-icon
                icon="mdi-check"
                small
                class="mr-4"
                v-on="on"
                @click="openModel(item._id)"
              />
            </template>
            <v-card>
              <v-card-title class="pa-4 headline grey lighten-2">
                Order confirmation
              </v-card-title>

              <v-card-text class="pa-4">
                Are you sure you want to Accept Order?
              </v-card-text>
              <v-card-actions class="pa-4 pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="acceptDialog = {}">
                  No
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    acceptOrder({ orderId: item._id, status: 'Completed' })
                  "
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="acceptDialog[`${item._id}-reject`]" width="400">
            <template v-slot:activator="{ on }">
              <base-icon icon="mdi-close" small class="mr-4" v-on="on" />
            </template>

            <v-card>
              <v-card-title class="pa-4 headline grey lighten-2">
                Cancel order confirmation
              </v-card-title>

              <v-card-text class="pa-4">
                Are you sure you want to cancel this order?
              </v-card-text>
              <v-card-actions class="pa-4 pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="acceptDialog = {}">
                  No
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    acceptOrder({ orderId: item._id, status: 'Cancelled' })
                  "
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
      </div>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import BaseButton from "@/components/BaseButton";
import BaseChip from "@/components/BaseChip";
import BaseIcon from "@/components/BaseIcon";

export default {
  components: { BaseTable, BaseButton, BaseChip, BaseIcon },

  data() {
    return {
      acceptDialog: {},
      dialog: false,
      page: 1,
      itemsPerPage: 100,
      pageLength: 5,
      openId: "",
      orders: {
        selected: [],
        search: "",
        headers: [
          // {
          //   text: "#",
          //   align: "start",
          //   sortable: true,
          //   value: "srNo"
          // },
          
          // { text: "Order ID", value: "orderId" },
          { text: "Name", value: "name" },
          { text: "Quantity", value: "quantity" },
          { text: "Total", value: "total" },
          { text: "Payment Method", value: "order" },
          { text: "Payment Status", value: "status" },
          { text: "Created At", value: "createdAt" },
          { text: "Actions", value: "actions" }
        ],
        items: [
          {
            createdAt: "01/01/2021",
            orderId: "#001",
            name: "manohar anthony",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid"
          },
          {
            createdAt: "01/01/2021",
            orderId: "#002",
            name: "joe james",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending"
          },
          {
            createdAt: "02/01/2021",
            orderId: "#003",
            name: "john walsh",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid"
          },
          {
            createdAt: "03/01/2021",
            orderId: "#004",
            name: "Bob Herm",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending"
          },
          {
            createdAt: "03/01/2021",
            orderId: "#005",
            name: "James Houston",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "refund"
          },
          {
            createdAt: "01/01/2021",
            orderId: "#006",
            name: "manohar anthony",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid"
          },
          {
            createdAt: "01/01/2021",
            orderId: "#007",
            name: "joe james",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending"
          },
          {
            createdAt: "02/01/2021",
            orderId: "#008",
            name: "john walsh",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "paid"
          },
          {
            createdAt: "03/01/2021",
            orderId: "#009",
            name: "Bob Herm",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "pending"
          },
          {
            createdAt: "03/01/2021",
            orderId: "#010",
            name: "James Houston",
            quantity: 7,
            total: "3000",
            paymentMethod: "Online",
            paymentStatus: "refund"
          }
        ]
      }
    };
  },

  computed: {
    itemsWithIndex() {
      return this.orders.items.map((items, srNo) => ({
        ...items,
        srNo: srNo + 1
      }));
    },
    allOrders() {
      return this.$store.state.order.allOrdersArray;
    }
  },
  methods: {
    viewOrder(orderId) {
      this.$router.push({ path: `/create-order/${orderId}` });
    },
    async acceptOrder(orderObj) {
      console.log("fghbjntrfyguhi", orderObj);
      let sendObj = {};
      // if (this.createOrderForm.status == "Cancel") {
      //   sendObj["rejectionReason"] = this.createOrderForm.rejectionReason;
      // }
      sendObj = {
        status: orderObj.status,
        orderId: orderObj.orderId
      };
      let updateProductData = await this.$store.dispatch(
        "order/updateOrder",
        sendObj
      );
      if (
        updateProductData &&
        updateProductData.data &&
        updateProductData.status == 200
      ) {
        this.$store.commit("snackbar/SET_OPEN", {
          text: "Order Status Changed successfully",
          color: "success"
        });
        await this.$store.dispatch("order/getAllOrderForAdmin", {});
      } else {
        this.$store.commit("snackbar/SET_OPEN", {
          text: updateProductData.data,
          color: "fail"
        });
        // this.$router.push("/orders");
      }

      // else {
      //   let saveProductData = await this.$store.dispatch(
      //     "product/saveProduct",
      //     data
      //   );
      //   if (
      //     saveProductData &&
      //     saveProductData.data &&
      //     saveProductData.status == 200
      //   ) {
      //     this.$store.commit("snackbar/SET_OPEN", {
      //       text: "Order Status successfully",
      //       color: "success"
      //     });
      //     this.$router.push("/orders");
      //   } else {
      //     this.$store.commit("snackbar/SET_OPEN", {
      //       text: "Something Went Wrong",
      //       color: "fail"
      //     });
      //   }
      // }
    },
    openModel(id) {
      this.openId = id;
    }
  }
};
</script>

<style></style>
