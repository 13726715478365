<template>
  <login />
</template>

<script>
import Login from "@/components/Auth/Login";
export default {
  name: "login-page",
  components: { Login },
};
</script>

<style></style>
