<template>
  <base-section title="Orders">
    <orders />
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import Orders from "@/components/Orders/Orders";

export default {
  components: { BaseSection, Orders },

  data() {
    return {
      title: "orders"
    };
  },

  mounted() {
    this.$store.commit("SET_TITLE", this.title);
  },
  async created() {
    await this.$store.dispatch("order/getAllOrderForAdmin", {});
  }
};
</script>

<style></style>
