var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{attrs:{"title":"Products"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('base-button',{staticClass:"mr-2",attrs:{"title":"Add","iconLeft":"mdi-plus","to":"/create-product"}}),_c('base-button',{attrs:{"title":"filter","outlined":"","iconLeft":"mdi-filter-variant"}}),_c('v-spacer'),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-2 w-full' : ''},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.products.search),callback:function ($$v) {_vm.$set(_vm.products, "search", $$v)},expression:"products.search"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.products.headers,"items":_vm.allProducts,"search":_vm.products.search,"item-key":"_id","show-select":"","hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.srNo",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2",staticStyle:{"width":"100px"}},[_c('v-img',{attrs:{"src":item.image}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("₹ " + item.amount))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-pencil","small":""},on:{"click":function($event){return _vm.editProduct(item._id)}}},on))]}}],null,true)}),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":"mdi-close","small":""}},on))]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4 headline grey lighten-2"},[_vm._v(" Cancel order confirmation ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" Are you sure you want to cancel this order? ")]),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Yes ")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }