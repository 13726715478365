<template>
  <base-section title="members">
    <members />
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection";
import Members from "@/components/Members/Members";
export default {
  name: "members-page",
  components: { BaseSection, Members },
  data() {
    return {
      title: "members",
    };
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.title);
  },
};
</script>

<style></style>
