import service from "../../service/apiService";
export default {
  namespaced: true,
  state: {
    allOrdersArray: [],
    oneProductObj: {}
  },
  mutations: {
    SET_ALL_ORDERS_DATA(state, value) {
      state.allOrdersArray = value;
    },
    SET_ONE_PRODUCTS_DATA(state, value) {
      state.oneProductObj = value;
    }
  },
  actions: {
    async getAllOrderForAdmin({ commit }, data) {
      try {
        console.log("commitcommit", commit);
        let getAllOrderForAdminData = await service.apiCallWithHeaderWithData(
          "/Order/getAllOrderForAdmin",
          data
        );
        console.log(
          "getAllOrderForAdminData getAllOrderForAdminData",
          getAllOrderForAdminData
        );
        if (
          getAllOrderForAdminData &&
          getAllOrderForAdminData.data &&
          getAllOrderForAdminData.status == 200
        ) {
          commit("SET_ALL_ORDERS_DATA", getAllOrderForAdminData.data);
        } else {
          commit("SET_ALL_ORDERS_DATA", []);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getOneOrder({ commit }, data) {
      try {
        console.log("getOneOrder", commit);
        let getOneOrderData = await service.apiCallWithHeaderWithoutData(
          `/Order/getOneOrder/${data.orderId}`
        );
        console.log("getOneOrderData getOneOrderData", getOneOrderData);
        return getOneOrderData;
      } catch (error) {
        console.log("error", error);
      }
    },
    async updateOrder({ commit }, data) {
      try {
        console.log("updateOrder", commit);
        let updateOrderData = await service.apiCallWithHeaderWithData(
          "/Order/acceptRejectOrderFromAdmin",
          data
        );
        console.log("updateOrderData updateOrderData", updateOrderData);
        return updateOrderData;
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
